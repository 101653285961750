/*  The css for the login page ! */
html {
  background-color: transparent; }

.login #login_error {
  padding: 0;
  margin: 0;
  background: none; }

.login h1 a {
  background-image: image-url("logo.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-background-size: cover;
  width: 320px;
  height: 97px;
  margin: 15px auto; }

body.login {
  background: #202020; }

/* Barre du haut - Lien */
.login p#backtoblog a:link, .login p#backtoblog a:visited {
  color: #6c7880; }

/* Barre du haut - Effet de survol*/
.login p#backtoblog a:hover, .login p#backtoblog a:active {
  color: #6c7880;
  text-decoration: underline; }

/* Modification de la taille du bloc "Connexion" */
#login {
  width: 400px;
  margin: 0em auto; }

/* Modification de bouton "Se connecter" et des autres liens*/
#login form .submit input {
  border-color: #bccad3 !important;
  color: #FFF !important;
  font-weight: bold;
  background-color: #0BA1D7;
  border-radius: 3px;
  text-shadow: rgba(0, 0, 0, 0.2) 0 1px 0; }

#login form .submit input:hover {
  color: #FFFFFF !important; }

.login #nav a {
  color: #7c8991 !important; }

.login #nav a:hover {
  color: #6f7a81 !important; }
